import { useCallback, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Alert from "@mui/material/Alert";

import { useFormStore } from "state/form.store";
import FormSteps from "components/forms/customer-forms/FormSteps";
import FormSelector from "components/forms/customer-forms/FormSelector";
import FormButtons from "components/forms/customer-forms/FormButtons";
import CTAFormHeader from "components/forms/customer-forms/CTAFormHeader";
import { useCustomerOutletContext } from "./customer";

const CustomerTradeApplicationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    processing,
    steps,
    handleInitialSave,
    handleSave,
    handleSubmit,
    innerDivRef,
    formNumber,
    activeStep,
    setActiveStep,
  } = useCustomerOutletContext();

  const { formData, completedSteps, failedSteps } = useFormStore();

  const progress = useMemo(() => {
    return (completedSteps.length / steps.length) * 100;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSteps]);

  const handleNextStep = useCallback(() => {
    const nextStep = steps[activeStep + 1].route;
    navigate(nextStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handlePreviouStep = useCallback(() => {
    const previousStep = steps[activeStep - 1].route;
    navigate(previousStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <Box
      sx={{
        flex: 1,
        p: { xs: 1, lg: 2 },
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box>
        <CTAFormHeader formNumber={formNumber} />
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: { xs: 1, sm: 0 } }}>
          <Typography sx={{ fontSize: { xs: 13, sm: 20 }, width: { xs: "30%", sm: "100%" }, fontWeight: 700 }}>
            {steps.find((step) => location.pathname.includes(step.route))?.label}
          </Typography>
          <Pagination
            sx={{ display: { xs: !!formNumber ? "block" : "none", sm: "none" } }}
            size="small"
            count={6}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              navigate(steps[value - 1].route);
              setActiveStep(value - 1);
            }}
            color="primary"
            page={activeStep + 1}
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  color:
                    item.page && failedSteps.includes(item.page - 1)
                      ? "red"
                      : item.page && completedSteps.includes(item.page - 1)
                      ? "green"
                      : "",
                  fontWeight: item.page && completedSteps.includes(item.page - 1) ? 500 : null,
                }}
                {...item}
              />
            )}
          />
        </Box>
      </Box>
      <LinearProgress sx={{ display: { xs: "block", sm: "none" } }} variant="determinate" value={progress} />
      <Paper sx={{ display: "flex", height: "96%" }} elevation={3}>
        <FormSelector
          steps={steps}
          completedSteps={completedSteps}
          failedSteps={failedSteps}
          activeStep={activeStep}
          stepOneComplete={!!formNumber}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ display: "flex", flexDirection: "column", p: 1, overflow: "auto" }}>
          {!!formData?.documentStatus ? (
            <Box sx={{ py: 1 }}>
              <Alert severity="success">This form is already submitted and cannot be edited!</Alert>
            </Box>
          ) : null}
          <FormSteps
            stepOneComplete={!!formNumber}
            steps={steps}
            completedSteps={completedSteps}
            failedSteps={failedSteps}
            activeStep={activeStep}
          />
          <Box
            ref={innerDivRef}
            sx={{
              width: "100%",
              overflowY: "scroll",
              px: { xs: 1, lg: 2 },
              pb: 6,
              pt: 3,
              "&::-webkit-scrollbar": {
                width: 8,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "lightGray",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "gray",
                borderRadius: 2,
              },
            }}
          >
            <Outlet />

            <FormButtons
              steps={steps}
              stepOneComplete={!!formNumber}
              handleSave={!!formNumber ? handleSave : handleInitialSave}
              handleNext={handleNextStep}
              handleBack={handlePreviouStep}
              handleSubmit={handleSubmit}
              completedSteps={completedSteps}
              activeStep={activeStep}
              processing={processing}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CustomerTradeApplicationForm;
