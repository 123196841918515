import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import FormControlItem, { FormControlItemLayout } from "components/forms/form-components/FormControlItem";
import {
  DRIVERS_LICENSE,
  GUARANTOR_LAYOUT_1,
  GUARANTOR_LAYOUT_2,
  TERMS_AND_CONDITIONS_CONFIRMATION,
  WITNESS_LAYOUT_1,
  WITNESS_LAYOUT_2,
} from "components/forms/form-layouts/customer-trade-application-layouts/terms-and-conditions.layout";
import { IS_JFC_AU } from "constants/global";
import { useFormStore } from "state/form.store";

const TermsAndConditionsOfSale = () => {
  const { formData, validating, updateForm } = useFormStore();

  const onChange = (value: any) => {
    updateForm({ ...value });
  };

  return (
    <Box>
      <Box sx={{ pb: 4, pt: 2 }}>
        <a href="https://www.jfcaustralia.com.au/terms" target="__blank">
          https://www.jfcaustralia.com.au/terms
        </a>
      </Box>
      <Grid item xs={12}>
        <FormControlItem
          data={formData}
          onChange={onChange}
          layout={TERMS_AND_CONDITIONS_CONFIRMATION}
          submitValidating={validating}
        />
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h6">Guarantor 1: (Owner or Director)</Typography>
        <Grid container spacing={1}>
          {GUARANTOR_LAYOUT_1.map((item: FormControlItemLayout) => {
            return (
              <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                <FormControlItem data={formData} onChange={onChange} layout={item} submitValidating={validating} />
              </Grid>
            );
          })}
        </Grid>

        <Box sx={{ py: 3 }}>
          <Typography sx={{ fontWeight: 500 }}>Please attach a valid driver's license (front and back)</Typography>
          <Typography variant="caption" sx={{ fontStyle: "italic", fontWeight: 500 }}>
            {`If not ${IS_JFC_AU ? "Australia" : "New Zealand"} Citizen, please attach a copy of the passport.`}
          </Typography>
          <Grid item xs={12}>
            <FormControlItem
              data={formData}
              onChange={onChange}
              layout={DRIVERS_LICENSE}
              submitValidating={validating}
            />
          </Grid>
        </Box>

        <Grid container spacing={1}>
          {WITNESS_LAYOUT_1.map((item: FormControlItemLayout) => {
            return (
              <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                <FormControlItem data={formData} onChange={onChange} layout={item} submitValidating={validating} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box sx={{ mt: 4, mb: 4 }} /> {/* Add spacing here */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 2 }}>
        <Typography variant="h6">Guarantor 2: (Owner or Director)</Typography>
        <Grid container spacing={2}>
          {GUARANTOR_LAYOUT_2.map((item: FormControlItemLayout) => {
            return (
              <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                <FormControlItem data={formData} onChange={onChange} layout={item} submitValidating={validating} />
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={1}>
          {WITNESS_LAYOUT_2.map((item: FormControlItemLayout) => {
            return (
              <Grid key={item.name} item xs={item.gridSizeMobile} sm={item.gridSizeTablet} lg={item.gridSizeDesktop}>
                <FormControlItem data={formData} onChange={onChange} layout={item} submitValidating={validating} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsOfSale;
