import { Route, Routes } from "react-router-dom";
import TaxiParking from "components/forms/employee-forms/taxi-parking-form/TaxiParking";
import PurchaseRequestForm from "components/forms/employee-forms/purchase-request-form/PurchaseRequestForm";
import Authentication from "pages/common/authentication";
import ForgotPassword from "pages/common/authentication/forgotpassword";
import ResetPassword from "pages/common/authentication/resetpassword";
import Login from "pages/common/authentication/signin";
import Signup from "pages/common/authentication/signup";
import CreateNewProduct from "pages/esph/createproduct";
import CreatePurchaseOrder from "pages/esph/createPurchaseOrder";
import CreateNewVendor from "pages/esph/CreateVendor";
import Dashboard from "pages/esph/dashboard";
import EditProduct from "pages/esph/editproduct";
import Invoices from "pages/esph/invoices";
import Products from "pages/esph/products";
import PurchaseOrder from "pages/esph/purchaseOrder";
import Vendors from "pages/esph/vendors";
import { ViewPo } from "pages/esph/viewPo";
import Users from "pages/common/users";
import CatchAllRoutes from "./CatchAllRoutes";
import Layout from "./Layout";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import GeneralExpense from "components/forms/employee-forms/general-expense-form/GeneralExpense";
import TravelExpense from "components/forms/employee-forms/travel-expense-form/TravelExpense";
import EntertainmentExpense from "components/forms/employee-forms/entertainment/EntertainmentExpense";
import GeneralExpensePreview from "components/pdfPreview/GeneralExpense/GeneralExpensePreview";
import TaxiParkingExpensePreview from "components/pdfPreview/TaxiParking/TaxiParkingExpensePreview";
import EntertainmentExpensePreview from "components/pdfPreview/Entertainment/EntertainmentExpensePreview";
import TravelExpensePreview from "components/pdfPreview/TravelExpense/TravelExpensePreview";
import PurchaseRequestPreview from "components/pdfPreview/Purchase/PurchaseRequestPreview";
import DoctorsInvoices from "pages/esph/doctors-invoices";
import ApplicantDetailsForm from "components/forms/customer-forms/form-sections/ApplicantDetailsForm";
import BusinessDetailsForm from "components/forms/customer-forms/form-sections/BusinessDetailsForm";
import PaymentInformation from "components/forms/customer-forms/form-sections/PaymentInformation";
import AcceptanceOfConditions from "components/forms/customer-forms/form-sections/AcceptanceOfConditions";
import TermsAndConditionsOfSale from "components/forms/customer-forms/form-sections/TermsAndConditionsOfSale";
import ReviewBeforeSubmission from "components/forms/customer-forms/form-sections/ReviewBeforeSubmission";
import CustomerTradeApplicationForm from "pages/jfc/customer/customer-trade-application-form";
import Customer from "pages/jfc/customer/customer";
import CTAFormPreview from "components/pdfPreview/customer-trade-application-form/CTAFormPreview";
import PaymentInformationForm from "pages/jfc/customer/payment-information-form";
import CreditCard from "components/forms/customer-forms/form-sections/payment-methods/CreditCard";
import PaymentInformationReviewBeforeSubmission from "components/forms/customer-forms/form-sections/payment-information/PaymentInformationReviewBeforeSubmission";
import PaymentInformationDirectDebit from "components/forms/customer-forms/form-sections/payment-information/PaymentInformationDirectDebit";
import PaymentInformationFormPreview from "components/pdfPreview/customer-trade-application-form/PaymentInformationFormPreview";
import PaymentInformationApplicantDetailsForm from "components/forms/customer-forms/form-sections/payment-information/PaymentInformationApplicantDetailsForm";
import PaymentInformationGuarantorDetails from "components/forms/customer-forms/form-sections/payment-information/PaymentInformationGuarantorDetails";
import EmailVerification from "pages/common/authentication/email-verification";
import AccountSetup from "pages/common/authentication/account-setup";
import WebsiteMaintenace from "pages/common/website-maintenace";
import MyForms from "pages/jfc/employee/my-forms";
import HomePage from "pages/esph/homePage";

//TEST HEROKU STACK UPDATE AND NODE VERSION TO 20

//TODO-ROMMEL: Explore code-splitting/lazy-loading
// const CustomerForm = lazy(() => import("pages/customer-form"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<PublicRoutes />}>
          <Route path="auth" element={<Authentication />}>
            <Route path="signin" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="account-setup" element={<AccountSetup />} />
          <Route path="customer" element={<Customer />}>
            <Route path="customer-trade-application-form" element={<CustomerTradeApplicationForm />}>
              <Route path="applicant_details" element={<ApplicantDetailsForm />} />
              <Route path="business_details" element={<BusinessDetailsForm />} />
              <Route path="payment_information" element={<PaymentInformation />} />
              <Route path="acceptance_of_conditions" element={<AcceptanceOfConditions />} />
              <Route path="terms_and_conditions_of_sale" element={<TermsAndConditionsOfSale />} />
              <Route path="review_before_submission" element={<ReviewBeforeSubmission />} />
            </Route>
            <Route path="payment-information-form" element={<PaymentInformationForm />}>
              <Route path="applicant_details" element={<PaymentInformationApplicantDetailsForm />} />
              <Route path="direct_debit" element={<PaymentInformationDirectDebit />} />
              <Route path="credit_card" element={<CreditCard />} />
              <Route path="guarantor_details" element={<PaymentInformationGuarantorDetails />} />
              <Route path="review_before_submission" element={<PaymentInformationReviewBeforeSubmission />} />
            </Route>
          </Route>
          <Route path="website-maintenance" element={<WebsiteMaintenace />} />
        </Route>

        <Route element={<ProtectedRoutes />}>
          {/* ##ESPH## */}
          <Route path="homepage" element={<HomePage />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="purchase-order" element={<PurchaseOrder />} />
          <Route path="create-purchase-order" element={<CreatePurchaseOrder />} />
          <Route path="create-new-vendor" element={<CreateNewVendor />} />
          <Route path="create-new-product" element={<CreateNewProduct />} />
          <Route path="edit-product" element={<EditProduct />} />
          <Route path="view-purchase-order" element={<ViewPo />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="products" element={<Products />} />
          <Route path="doctors-invoices" element={<DoctorsInvoices />} />

          {/* ##JFC## */}
          <Route path="my-form" element={<MyForms />} />
          <Route path="purchase-request" element={<PurchaseRequestForm />} />
          <Route path="general-expense" element={<GeneralExpense />} />
          <Route path="taxi-parking" element={<TaxiParking />} />
          <Route path="entertainment" element={<EntertainmentExpense />} />
          {/* <Route path="entertainment-party" element={<EntertainmentExpense />} /> */}
          {/* <Route path="travel" element={<TravelExpense />} /> */}

          {/* ##COMMON## */}
          <Route path="users" element={<Users />} />
        </Route>

        <Route path="*" element={<CatchAllRoutes />} />
      </Route>
      <Route path="/purchase-request-form/:documentNumber" element={<PurchaseRequestPreview />} />
      <Route path="/general-expense-form/:documentNumber" element={<GeneralExpensePreview />} />
      <Route path="/entertainment-expense-form/:documentNumber" element={<EntertainmentExpensePreview />} />
      {/* <Route
        path="/entertainment-party-expense-form/:documentNumber"
        element={<EntertainmentExpensePreview />}
      /> */}
      <Route path="/taxiparking-expense-form/:documentNumber" element={<TaxiParkingExpensePreview />} />
      <Route path="/travel-expense-form/:documentNumber" element={<TravelExpensePreview />} />
      <Route path="/customer-trade-application-form/:formId" element={<CTAFormPreview />} />
      <Route path="/payment-information-form/:formId" element={<PaymentInformationFormPreview />} />
    </Routes>
  );
};

export default AppRoutes;
