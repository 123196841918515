export enum CUSTOMER_DOCUMENT_TYPES {
  CUSTOMER_TRADE_APPLICATION_FORM = "Customer Trade Application Form",
  CUSTOMER_PAYMENT_INFORMATION_FORM = "Payment Information Form",
}

export enum PaymentMethod {
  DirectDebit = "Direct Debit",
  AFTAdvancePayment = "EFT - Advance payment",
  EFTOnTheDeliveryDate = "EFT - On the delivery date",
  CreditCard = "Credit Card",
}

export enum CTA_FORM_FIELDS {
  DWDOCID = "ACCOUNT_PAYABLE",
  DWDOCSIZE = "Expense Invoices | Approvers",
  DWSTOREUSER = "Employees",
  DWSTOREDATETIME = "NZ Employee Claim | GL Codes",
  DWEXTENSION = "NZ Accounts Payable",
  DWMODUSER = "Employee_Claim_GL_Codes",
  DWMODDATETIME = "DWMODDATETIME",
  DWSECTIONCOUNT = "DWSECTIONCOUNT",
  DWPAGECOUNT = "DWPAGECOUNT",
  DWDISK = "DWDISK",
  DWDISKNO = "DWDISKNO",

  DOCUMENT_TYPE = "DOCUMENT_TYPE",
  STATUS = "STATUS",
  WORKFLOW_STATUS = "WORKFLOW_STATUS",
  APPROVER = "APPROVER",
  APPLICATION_NUMBER = "APPLICATION_NUMBER",

  //APPLICANT DETAILS
  APPLICANT_LEGAL_NAME = "APPLICANT_LEGAL_NAME", //
  APPLICANT_TRADING_NAME = "APPLICANT_TRADING_NAME", //
  ABN = "ABN", //
  NZBN = "NZBN", //
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  BRANCH = "BRANCH", //
  CUSTOMER_NUMBER = "CUSTOMER_NUMBER", //

  //DELIVERY ADDRESS
  SHOP_NUMBER_DELIVERY_ADDRESS = "SHOP_NUMBER_DELIVERY_ADDRESS", //
  SUBURB_DELIVERY_ADDRESS = "SUBURB_DELIVERY_ADDRESS", //
  STATE_DELIVERY_ADDRESS = "STATE_DELIVERY_ADDRESS", //
  POSTCODE_DELIVERY_ADDRESS = "POSTCODE_DELIVERY_ADDRESS", //
  STREET_NUMBER_DELIVERY_ADDRES = "STREET_NUMBER_DELIVERY_ADDRES", //
  STREET_NAME_DELIVERY_ADDRESS = "STREET_NAME_DELIVERY_ADDRESS", //
  BUSINESS_PHONE_DELIVERY_ADDRE = "BUSINESS_PHONE_DELIVERY_ADDRE",

  //REFERENCE
  REFERENCE_1 = "REFERENCE_1", //
  REFERENCE1_CONTACT_PHONE = "REFERENCE1_CONTACT_PHONE", //
  REFERENCE_2 = "REFERENCE_2", //

  //MISC1
  TYPE = "TYPE", //
  STORE_CONTACT_MOBILE_NUMBER = "STORE_CONTACT_MOBILE_NUMBER", //
  STORE_CONTACT_NAME = "STORE_CONTACT_NAME", //
  SHOP_CONTACT_POSITION = "SHOP_CONTACT_POSITION", //
  LIQUOR_LICENSE = "LIQUOR_LICENSE", //
  DEL_TIME = "DEL_TIME", //

  //MISC2
  IS_EXISTENT_CUSTOMER = "IS_EXISTENT_CUSTOMER", //

  GUARANTOR_EMAIL = "GUARANTOR_EMAIL",
  GUARANTOR_FULL_NAME = "GUARANTOR_FULL_NAME",
  GUARANTOR_MOBILE = "GUARANTOR_MOBILE",
  SALES_REPRESENTATIVE1 = "SALES_REPRESENTATIVE1",
  TERM = "TERM",
  TERRITORY = "TERRITORY",
  ID_CHECK = "ID_CHECK",
  INDUSTRY = "INDUSTRY",
  DEL_CODE = "DEL_CODE",
  TARGET = "TARGET",
  AUTHORISED_BY = "AUTHORISED_BY",
  SALES_NUMBER = "SALES_NUMBER",
  BANK_ACCOUNT_NAME = "BANK_ACCOUNT_NAME",
  BSB_NUMBER = "BSB_NUMBER",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  TERMS_CODE = "TERMS_CODE",
  WORKFLOW_NAME = "WORKFLOW_NAME",
  BANK_NAME = "BANK_NAME",
  SAGE_CUSTOMER_NAME = "SAGE_CUSTOMER_NAME",
  SAGE_CUSTOMER_ADDRESS_LINE_1 = "SAGE_CUSTOMER_ADDRESS_LINE_1",
  SAGE_CUSTOMER_ADDRESS_LINE_2 = "SAGE_CUSTOMER_ADDRESS_LINE_2",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  DATE = "DATE",
  SALES_REPRESENTATIVE = "SALES_REPRESENTATIVE",
}
