import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { grey } from "@mui/material/colors";
import { FC } from "react";
import { IS_JFC_AU } from "constants/global";

interface FormHeaderProps {
  formNumber?: number;
}

const CTAFormHeader: FC<FormHeaderProps> = ({ formNumber }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "flex-start", sm: "center" } }}>
        {IS_JFC_AU ? (
          <img src="/static/img/JFC-LOGO-AU.png" alt="JFC" style={{ maxWidth: matches ? 220 : 320 }} />
        ) : (
          <img src="/static/img/JFC-LOGO-NZ.png" alt="JFC" style={{ maxWidth: 360 }} />
        )}
      </Box>
      {formNumber ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Typography fontWeight={600} sx={{ color: grey[800], fontSize: { xs: 10, sm: 14 } }}>
            Application Number
          </Typography>
          <Typography fontWeight={600} sx={{ color: grey[800], fontSize: { xs: 12, sm: 16 } }}>
            {formNumber}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default CTAFormHeader;
