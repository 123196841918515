import { IS_JFC, IS_JFC_AU } from "constants/global";
import React, { FC, PropsWithChildren } from "react";

const AppLogo: FC<PropsWithChildren<{ style?: React.CSSProperties | undefined }>> = ({ style }) => {
  return IS_JFC ? (
    <img
      src={IS_JFC_AU ? "/static/img/JFC-LOGO-AU.png" : "/static/img/JFC-LOGO-NZ.png"}
      alt="JFC"
      style={{ ...(style ?? {}), maxWidth: 300 }}
    />
  ) : (
    <img src="/static/img/esph.png" alt="ESPH" style={{ ...(style ?? {}), maxWidth: 300 }} />
  );
};

export default AppLogo;
