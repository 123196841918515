import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { DialogProps } from "interfaces/common.interfaces";
import AppLogo from "components/common/AppLogo";

import { useFormStore } from "state/form.store";

interface FormSavedDialogProps extends DialogProps {
  formNumber?: number;
}

const FormSavedDialog: FC<FormSavedDialogProps> = ({ open, handleClose, formNumber }) => {
  const { formData } = useFormStore();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          <AppLogo />
        </DialogTitle>

        <DialogContent sx={{ px: { xs: 2, md: 5 } }}>
          <Typography variant="h5" sx={{ fontWeight: 600, pb: 3 }}>
            Form Saved
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography>Dear {formData?.APPLICANT_LEGAL_NAME ?? ""}</Typography>
            <Typography color="error">You'll receive an email to continue with the form saved.</Typography>
            <Typography>Application number: {formNumber}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Button sx={{ width: "50%" }} variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormSavedDialog;
