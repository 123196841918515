import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import FormControlItem from "components/forms/form-components/FormControlItem";
import { PAYMENT_METHOD_LAYOUT } from "components/forms/form-layouts/customer-trade-application-layouts/payment-information.layout";
import { useFormStore } from "state/form.store";
import DirectDebit from "./payment-methods/DirectDebit";
import EFTAdvancePayment from "./payment-methods/EFTAdvancePayment";
import EFTOnTheDeliveryDate from "./payment-methods/EFTOnTheDeliveryDate";
import CreditCard from "./payment-methods/CreditCard";
import { PaymentMethod } from "enums/customer-trade-application-form.enums";
import { Typography } from "@mui/material";
import { IS_JFC_AU } from "constants/global";

const PaymentInformation = () => {
  const { formData, validating, updateForm } = useFormStore();

  const handleChange = (event: SelectChangeEvent<any>) => {
    updateForm({ ...formData, PAYMENT_METHOD: event.target.value });
  };

  const renderPaymentForm = () => {
    switch (formData?.PAYMENT_METHOD) {
      case PaymentMethod.DirectDebit:
        return <DirectDebit />;
      case PaymentMethod.AFTAdvancePayment:
        return <EFTAdvancePayment />;
      case PaymentMethod.EFTOnTheDeliveryDate:
        return <EFTOnTheDeliveryDate />;
      case PaymentMethod.CreditCard:
        return <CreditCard />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Grid item xs={12} sx={{ pb: 2 }}>
        {/* <FormControlItem
                    data={formData}
                    onChange={onChange}
                    layout={PAYMENT_METHOD_LAYOUT}
                    submitValidating={validating}
                /> */}
        {IS_JFC_AU && (
          <Typography variant="subtitle2" sx={{ fontStyle: "italic", mb: 2 }}>
            If you are a new customer in ACT and NSW, please select "Direct Debit" only
          </Typography>
        )}

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{ fontWeight: 600, fontSize: 18 }}>
            Payment method
          </InputLabel>
          <Select value={formData?.PAYMENT_METHOD} label="Payment method" onChange={handleChange}>
            <MenuItem value="Direct Debit">
              Direct Debit: It is an automatic transaction that transfers money from your account to another.
            </MenuItem>
            <MenuItem value="EFT - Advance payment">
              EFT - Advance payment: It is an electronic funds transfer.
            </MenuItem>
            <MenuItem value="EFT - On the delivery date">
              EFT - On the delivery date: It is an electronic funds transfer.
            </MenuItem>
            <MenuItem value="Credit Card">Credit Card: Merchant fees may be applied.</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {renderPaymentForm()}
    </Box>
  );
};

export default PaymentInformation;
